// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import bgImage from "assets/images/paineis-solares.jpg";
import bgImagePainelEspecialista from "assets/images/paineis-solares-especialista.jpg";

function Download() {
  return (
    <MKBox component="section" py={{ xs: 0, sm: 12 }}>
      <MKBox
      width="100%"
        variant="gradient"
        bgColor="dark"
        position="relative"
        borderRadius="xl"
        sx={{ overflow: "hidden" }}
      >
        
        <Container src={bgImage} sx={{ display: "flex", zIndex: 10, py: 10, 
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",

          backgroundImage: ({
            palette: { gradients },
            functions: { rgba, linearGradient },
          }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover", }}
          >
          <Grid container item md={8} justifyContent="left" mx={0} textAlign="left">
            <MKTypography variant="h2" color="white">
              Procurando economia?
            </MKTypography>
            <MKTypography variant="h4" color="white" mb={1}>
              Deseja diminuir seus custos com energia elétrica?
            </MKTypography>
            <MKTypography variant="body2" size="1px" color="white" mb={6}>
              
A nossa empresa tem a satisfação de apresentar serviços especializados na implementação de sistemas de geração de energia solar fotovoltaica. Destacamo-nos pela abordagem notável, caracterizada pela descomplicação, confiabilidade e eficácia. Esses princípios são cuidadosamente incorporados em cada fase do processo, desde o planejamento até a execução, garantindo resultados excepcionais. A nossa equipe, composta por profissionais altamente qualificados, desempenha um papel fundamental nesse processo.
            </MKTypography>
            <MKButton
              variant="gradient"
              color="contatcLP"
              size="large"
              component="a"
              href="#contacts"
              sx={{ mb: 2 }}
              zIndex={1}
            >
              Faça uma simulação
            </MKButton>
          </Grid>
        </Container>
      </MKBox>
    </MKBox>
  );

};

export default Download;
