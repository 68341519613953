import breakpoints from "assets/theme/base/breakpoints";
import { Fragment, useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Images
import appleLogo from "assets/images/logos/gray-logos/logo-apple.svg";
import facebookLogo from "assets/images/logos/gray-logos/logo-facebook.svg";
import nasaLogo from "assets/images/logos/gray-logos/logo-nasa.svg";
import vodafoneLogo from "assets/images/logos/gray-logos/logo-vodafone.svg";
import digitalOceanLogo from "assets/images/logos/gray-logos/logo-digitalocean.svg";

import Download from "pages/LP/sections/Download";

function Testimonials() {
  return (
    <MKBox component="section" py={12}>
      <Grid width="100%" alignItems="center" textAlign="center" >
      <Divider variant="gradients" borderColor="orange" sx={{width:"calc(100%)-248px",opacity:0.7, mt: 2, mb: 14, mx:10 }} />

      </Grid>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          justifyContent="center"
          sx={{ mx: "auto", textAlign: "center" }}
        >

        </Grid>
          <MKTypography variant="h2" textAlign="center" color="dark" mb={2}>
          Economize até 90% na sua conta de luz. Financiamento FACILITADO e BAIXO custo! 💡
          </MKTypography>
      < Download />
      </Container>
    <Grid>

    </Grid>
    </MKBox>
    
  );
}
export default Testimonials;
