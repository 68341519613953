import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

import Information from "pages/LP/sections/Information";
import Testimonials from "pages/LP/sections/Testimonials";

import Contact from "pages/LP/sections/Contact";
// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/paineis-especialista.png";
import bgImageMobile from "assets/images/paineis-especialista-mobile.png";
import seta from "assets/images/seta-para-cima.png";
import MKButton from "components/MKButton";


function LP() {
  return (
    <>
    
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "",
          href: "#contacts",
          label: "Faça uma simulação",
          color: "contatcLP",
        }}
        sticky
      />

      <div id="inicio"></div>
      {/* whatsapp */}
      <Grid width={"100%"}
      class="buttonWhatsIndex"
        left={0}>
        <Grid
          position="fixed"
          zIndex={100}
          bottom={30}
          left={"10vh"}
          textAlign={"right"}
        >
          <MKButton
          
            href="https://santoenergia.com.br/wpr/whatsapp"
            backgroundImage={seta}
            backgroundPosition="center"
            target="_blank"
            color="whatsapp"
            size="large"
            circular={true}
            iconOnly={true}
            >
            <svg xmlns="http://www.w3.org/2000/svg" width="90" height="90" fill="currentColor" class="bi bi-arrow-up-circle-fill" viewBox="0 0 16 16">
              <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232" />
            </svg>
          </MKButton>
        </Grid>
      </Grid>
      {/* arrowup */}
      <Grid width={"100%"}
        right={0}>
        <Grid
          position="fixed"
          zIndex={100}
          bottom={30}
          right={"10vh"}
          textAlign={"right"}
        >
          <MKButton
            href="#inicio"
            backgroundImage={seta}
            backgroundPosition="center"
            color="contatcLP"
            size="large"
            circular={true}
            iconOnly={true}>
            <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="currentColor" class="bi bi-arrow-up-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0m-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707z" />
            </svg>
          </MKButton>
        </Grid>
      </Grid>
      <MKBox
        minHeight="100vh"
        width="100%"
        sx={{

          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",

          backgroundImage: ({
            palette: { gradients },
            functions: { rgba, linearGradient },
          }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.2),
              rgba(gradients.dark.state, 0.2)
            )}, url(${window.innerWidth > 1000 ? bgImage : bgImageMobile})`,
          backgroundSize: "cover",

        }}
      >
        <Container>
          <Grid container item xs={12} lg={4} alignContent="Left" mx="">
            <MKTypography
              variant="h1"
              color="white"
              fontSize="5vh"
              mt={4}
              mb={'1em'}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
             Pare de desperdiçar dinheiro! Economize com energia solar a partir de hoje.
            </MKTypography>
            <p

            style={{
              marginTop:-20,
              variant:"body1",
              color:"white",
              textAlign:"left",
            }
            }
            >
              Chega de sustos na conta de luz! Experimente a liberdade da energia solar.
            </p>
            <Grid container item mt={5} lg={6} alignContent="Left" mx="">
              <MKButton size="medium" href="#contacts" color="orange">Faça uma simulação</MKButton>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        {/* <Counters /> */}
        <div id="oque"></div>
        <Information />
        <div id="beneficios"></div>
        <Grid width="100%" alignItems="center" textAlign="center" >
          <Divider variant="gradient" borderColor="orange" sx={{ width: "calc(100%)-248px", opacity: 0.7, mt: 2, mb: 14, mx: 10 }} />

        </Grid>

        <Container>
          <Grid container spacing={3}>

            <Grid item xs={12} lg={6}>
              <a href="#contacts">
                <FilledInfoCard
                  variant="gradients"
                  color="#1660ab"
                  icon="flag"
                  title="Faça sua simulação"
                  description="Verifique a possibilidade de trabalharmos juntos para um mundo mais sustentável."
                  action={{
                    type: "internal",
                    label: "Quero começar",
                    href: "#contacts",

                  }}
                />

              </a>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FilledInfoCard
                color="info"
                icon="precision_manufacturing"
                title="Eficiência dos Painéis Solares"
                description="A eficiência dos painéis solares tem melhorado significativamente ao longo dos anos.
                Hoje a eficiência dos paineis é superior a 20%, convertendo mais da luz solar incidente em eletricidade."


              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <FilledInfoCard
                color="info"
                icon="apps"
                title="Controle tecnológico"
                description="A importancia monitoramento solar para acompanhar produção por meio de softwares em tempo real, identificando rapidamente problemas e otimizando baseado em dados."

              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <FilledInfoCard
                color="info"
                icon="speed"
                title="Agilidade e acessibilidade "
                description="Uma vez instalado e em funcionamento, as poucas manutenções básicas são a limpeza dos módulos"

              />
            </Grid>
          </Grid>
        </Container>

        {/* <Featuring/> */}
        <div id="quem"></div>
        <Testimonials />
        <Grid width="100%" alignItems="center" textAlign="center" >
          <Divider variant="gradients" borderColor="orange" sx={{ width: "calc(100%)-248px", opacity: 0.7, mt: 2, mb: 14, mx: 10 }} />

        </Grid>
        <div id="contacts"></div>
        <Contact />

      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>



    </>
  );
}

export default LP;
