// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
// import bgFront from "assets/images/rotating-card-bg-front.jpeg";
// import bgBack from "assets/images/rotating-card-bg-back.jpeg";

function Information() {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>

        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={6} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={16} md={10}>
                <h2>Por que investir em energia solar?</h2>
                <DefaultInfoCard

                  title=""
                  description=" Além de reduzir seus custos de energia a longo prazo, você também está garantindo um investimento sólido e seguro para o futuro. Com a energia solar, você corta gastos com sua conta de eletricidade e protege-se contra aumentos nas tarifas. É uma oportunidade de economia inteligente que traz retornos financeiros consistentes. Não deixe passar essa chance de investir em um futuro mais sustentável e econômico."
                />
              </Grid>

            </Grid>

          </Grid>
          <Grid item xs={12} lg={6} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront

                icon="touch_app"
                title={
                  <>
                    Explore o futuro da energia limpa, reduza suas contas e abrace uma solução sustentável.

                    <br />

                  </>
                }
                description=""
              />
              <RotatingCardBack
                title="Entenda mais sobre o assunto"
                description="Avalie propostas com um especialista e tenha mais ecônomia"
                action={{
                  type: "internal",
                  href: "#contacts",
                  label: "Faça uma simulação",
                }}
              />
            </RotatingCard>
          </Grid>

        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
