 

const routes = [
  {
    name: "Inicio",
    href: "/#inicio",
  },
  {
    name: "Por que?",
    href: "/#oque",
  },
  {
    name: "Beneficios",
    href: "/#beneficios",
  },
  {
    name: "Orçamento",
    href: "/#contacts",
  },
  {
    name: "Quem Somos?",
    href: "/#quem",
  },
  
  
];

export default routes;
