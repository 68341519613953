  
// @mui material components
import Container from "@mui/material/Container";

import axios from "axios";
import Grid from "@mui/material/Grid";
import { Select, MenuItem, Menu } from "@mui/material";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import { useState } from "react";
import React from "react";
import { redirect } from "react-router-dom";
// Images
// import bgImage from "assets/images/examples/blog2.jpg";

function Contact() {
  const [post, setPost] = React.useState(null);
  const [formValues, setFormValues] = useState({});
  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.id]: e.target.value });
  };
  const handleChangeWhatsapp = (e) => {
    debugger
    if(e.target.id != ""){

      var tel = e.target.value
        tel=tel.replace(/\D/g,"")
        tel=tel.replace(/(.{2})(\d)/,"($1)$2")
        tel=tel.replace(/(.{7})(\d)/,"$1$2")
        if(tel.length == 12) {
            tel=tel.replace(/(.{1})$/,"-$1")
        } else if (tel.length == 13) {
            tel=tel.replace(/(.{2})$/,"$1")
        } else if (tel.length == 14) {
            tel=tel.replace(/(.{3})$/,"$1")
        } else if (tel.length == 15) {
            tel=tel.replace(/(.{4})$/,"-$1")
        } else if (tel.length > 15) {
            tel=tel.replace(/(.{4})$/,"-$1")
        }
        e.target.value = tel;
        //this.setState({[e.target.id]: tel});
        setFormValues({ ...formValues,["whatsapp"]:  e.target.value });
    }
  };
  const handleChangeSelect = (e) => {
    e.preventDefault();
    setFormValues({ ...formValues,[e.target.name]:  e.target.value });
  };
  const handleSubmit = (e) => {

    // e.preventDefault();
    debugger
    
    const json = JSON.stringify(formValues)
    var myData =json;
   
    localStorage.setItem( 'objectToPass', myData ); 
  };
  var emailMobile = ""   


    var windowInner = window.innerWidth
    if(windowInner < 1200){

      emailMobile = "comercial @santoenergia .com.br"
    }
    else if(windowInner > 1200 && windowInner < 1400){

      emailMobile = "comercial@santoenergia .com.br"
    }
    else{
      emailMobile = "comercial@santoenergia.com.br"

    }
 
  return (

    <MKBox component="section" py={{ xs: 0, lg: 6 }}>
      <Container>
        <MKTypography variant="h2" color="Dark" textAlign="center" mb={2}>
          Entre em contato com nossa equipe.
        </MKTypography>
        <MKTypography variant="body2" color="text" textAlign="center" mb={8}>
          Tire suas dúvidas e entenda melhor como a energia solar pode fazer a diferença!
        </MKTypography>
        <Grid container item>
          <MKBox
            width="100%"
            bgColor="white"
            borderRadius="xl"
            shadow="xl"
            mb={6}
            sx={{ overflow: "hidden" }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                lg={5}
                position="relative"
                px={0}
                sx={{
                
                  backgroundColor: "#1660ab",
                  opacity:0.9
                }}
              >
                <MKBox
                  mt={16}
                  height="100%"
                  variant="body1"
                >
                  <MKBox py={6} pr={6} pl={{ xs: 6, sm: 12 }}  my="auto">
                    <MKTypography variant="h3" color="white" mb={1}>
                      Informações para contato
                    </MKTypography>
                    <MKTypography variant="body2" color="white" opacity={0.8} mb={3}>
                      Atendimentos de segundas as sextas das 8h às 18h
                    </MKTypography>
                    <MKBox display="flex" p={1}>
                      <MKTypography variant="h4" color="white">
                        <i className="fa fa-whatsapp" />
                      </MKTypography>
                      <MKTypography
                      href={"https://santoenergia.com.br/wpr/whatsapp"}
                        component="a"
                        variant="h4"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                       11 95654-9016
                      </MKTypography>
                    </MKBox>
                    <MKBox display="flex" p={1}>
                      <MKTypography variant="h4" color="white">
                        <i className="fas fa-phone" />
                      </MKTypography>
                      <MKTypography
                        component="span"
                        variant="h4"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        11 2440-2089
                      </MKTypography>
                    </MKBox>
                    <MKBox display="flex" color="white" p={1}>
                      <MKTypography variant="h4" color="white">
                        <i className="fas fa-envelope" />
                      </MKTypography>
                      <MKTypography
                        component="span"
                        variant="h4"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        {emailMobile}                     
                      </MKTypography>
                    </MKBox>
                    <MKBox display="flex" color="white" p={1}>
                      <MKTypography variant="h4" color="white">
                        <i className="fas fa-map-marker-alt" />
                      </MKTypography>
                      <MKTypography
                      href={"https://www.google.com/maps/place/R.+Aurora+do+Norte,+30+-+Vila+Barros,+Guarulhos+-+SP,+07193-250/@-23.446062,-46.5066576,17z/data=!3m1!4b1!4m6!3m5!1s0x94ce8ab8805a7507:0x1d815504b055e30a!8m2!3d-23.446062!4d-46.5040827!16s%2Fg%2F11lcc377jh?entry=ttu"}
                        component="a"
                        variant="h4"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                       Endereço: R. Aurora do Norte, 30 - Vila Barros, Guarulhos - SP, 07193-250
                      </MKTypography>
                    </MKBox>
                    <MKBox mt={3}>
                    </MKBox>
                  </MKBox>
                </MKBox>
              </Grid>
              <Grid item xs={12} lg={7}>
                <MKBox component="form" action="/wpr" p={2} method="post" role="form" onSubmit={handleSubmit}>
                  <MKBox px={3} py={{ xs: 2, sm: 6 }}>
                    <MKTypography variant="h2" mb={1}>
                    Faça uma simulação rápida
                    </MKTypography>
                    <MKTypography variant="body2" color="text" mb={2}>
                      Preencha o formulário e nos envie uma simulação.
                    </MKTypography>
                  </MKBox>
                  <MKBox pt={0.5} pb={3} px={3}>
                    <Grid container>
                      <Grid item xs={12} pr={1} mb={4}>
                      <MKTypography variant="body2" mb={0}>
                      Seu nome
                    </MKTypography>

                    
                        <MKInput
                        required
                        id="name"
                          variant="standard"
                          onChange={handleChange}
                       
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} pr={1} mb={4}>
                      <MKTypography variant="body2" mb={0}>
                      Seu e-mail
                    </MKTypography>
                        <MKInput
                        required
                          variant="standard"
                          id="email"
                          onChange={handleChange}
                 
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={12} pr={1} mb={4}>
                      <MKTypography variant="body2" mb={0}>
                      Cidade
                    </MKTypography>
                        <MKInput
                        required
                          id="cidade"
                          variant="standard"
                          onChange={handleChange}
                          
                          
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          />
                     
                      </Grid>

                      <Grid item xs={12} pr={1} mb={4}>
                      <MKTypography variant="body2" mb={0}>
                      Whatsapp
                    </MKTypography>
                        <MKInput
                        required
                          id="whatsapp"
                          variant="standard"
                          onChange={handleChangeWhatsapp}
                          
                          
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          />
                     
                      </Grid>
                      <Grid item xs={12} pr={1} mb={8}>
                      <MKTypography variant="body2" mb={0}>
                      Qual valor da sua conta de luz?
                    </MKTypography>
                    <Select required id="ContaEnergia" onChange={handleChangeSelect}   name="ContaEnergia"  variant="standard"
                          fullWidth>
                            <MenuItem disabled selected>Selecione uma opção</MenuItem>
                            <MenuItem value={1}>Menos de 300R$</MenuItem>
                            <MenuItem value={2}>Entre 300R$ e 1000R$</MenuItem>
                            <MenuItem value={3}>Acima de 1000R$</MenuItem>
                          </Select>
                         
                         
                      </Grid>
                    </Grid>




                    <Grid
                      container
                      item
                      xs={12}
                      md={6}
                      justifyContent="flex-end"
                      textAlign="center"
                      mr="auto"
                      ml="auto"
                      
                    >
                      <MKButton type="submit" className="buttonSendForm" fullWidth color="contatcLP">
                        Fazer simulação
                      </MKButton>
                    </Grid>
                  </MKBox>
              
                </MKBox>
              </Grid>
            </Grid>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Contact;
