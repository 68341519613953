// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";
import { Instagram, Facebook } from "@mui/icons-material";

// Images
import LogoSantoEnergia from "assets/images/logo-santo-energia.png";

const date = new Date().getFullYear();

export default {
  brand: {
    image:LogoSantoEnergia,
    route: "/",
  },
  infos:[
      {
        whatsappNumber:"11 95654-9016",
        cnpj:"44.009.856/0001-01",
        end:"R. Aurora do Norte, 30 - Vila Barros, Guarulhos - SP, 07193-250",
        telefone:"11 2440-2089"
      }
     ],
  socials: [
    {
      icon: <Facebook />,
      link: "https://www.facebook.com/SantoEnergia/",
    },
    {
      icon: <InstagramIcon />,
      link: "https://www.instagram.com/SantoEnergia",
    },
  ],
  menus: [
    {
      name: "Menus",
      items: [
        { name: "Inicio", href: "#inicio" },
        { name: "Por que?",  href: "#oque"},
        { name: "Benefícios",  href: "#beneficios" },
        { name: "Orçamento",  href: "#contacts"},
        { name: "Quem Somos?",  href: "#quem" },
      ],
    },
    
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} D&D Consulting
      <MKTypography
        component="a"
        href="/"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
      </MKTypography>
      .
    </MKTypography>
  ),
};
