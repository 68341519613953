
// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import breakpoints from "assets/theme/base/breakpoints";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function DefaultFooter({ content }) {
  const { brand, infos, socials, menus, copyright } = content;
  const {
    values: { sm, md, lg, xl, xxl },
  } = breakpoints;
  
  return (
    <MKBox component="footer">
      <Container  >
        <Grid container spacing={3} flexWrap={
          window.innerWidth <= 600 ? "wrap !important":"nowrap !important"}
           sx={{mt:2 }}
           >
          <Grid item  xs={12}  md={6} sx={{maxWidth:"fit-content" ,ml: 4, mb: 3, mt:1, mr:-20 }}>
            <MKBox>
              <Link to={brand.route}>
                <MKBox component="img" src={brand.image} alt={brand.name} maxWidth="12rem" mb={2} />
              </Link>
              <MKTypography variant="h6">{brand.name}</MKTypography>
            </MKBox>
            <MKBox display="flex" alignItems="center" mt={-2} ml={0} >
              {socials.map(({ icon, link }, key) => (
                <MKTypography
                
                  key={link}
                  component="a"
                  href={link}
                  target="_self"
                  rel="noreferrer"
                  variant="icon"
                  color="dark"
                  opacity={0.8}
                  mr={key === socials.length - 1 ? 0 : 2.5}
                >
                  {icon}
                </MKTypography>
              ))}
            </MKBox>
          </Grid>
          <Grid item  xs={12} md={9} sx={{ ml: 0, mt: 0 }}>
          <MKBox display="flex" alignItems="center" mt={3}>
              {infos.map(({ cnpj, end,telefone, whatsappNumber }, key) => (
                <MKTypography
                  component="a"
                  target="_self"
                  rel="noreferrer"
                  variant="body2"
                  color="dark"
                  opacity={0.8}
                >
                 <MKBox display="flex" p={1}>
                 <MKTypography variant="h5" color="dark">
                        <i className="fa fa-whatsapp" />
                      </MKTypography>
                      <MKTypography
                      href={"https://santoenergia.com.br/wpr/whatsapp"}
                        component="a"
                        variant="h5"
                        color="dark"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                       
                      >
                        {whatsappNumber}
                      </MKTypography>
                      </MKBox>
                      <MKBox display="flex" p={1}>
                      <MKTypography variant="h5" color="dark">
                        <i className="fas fa-phone" />
                      </MKTypography>
                      <MKTypography
                        component="span"
                        variant="h5"
                        color="dark"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        {telefone}
                      </MKTypography>
                    </MKBox>
                    <MKBox display="flex" color="dark" p={1}>
                      <MKTypography variant="h5" color="dark">
                        <i className="fas fa-building" />
                      </MKTypography>
                      <MKTypography
                        component="span"
                        variant="h5"
                        color="dark"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        {cnpj}
                      </MKTypography>
                    </MKBox>
                    <MKBox display="flex" color="dark" p={1}>
                      <MKTypography variant="h5" color="dark">
                        <i className="fas fa-map-marker-alt" />
                      </MKTypography>
                      <MKTypography
                      href={"https://www.google.com/maps/place/R.+Aurora+do+Norte,+30+-+Vila+Barros,+Guarulhos+-+SP,+07193-250/@-23.446062,-46.5066576,17z/data=!3m1!4b1!4m6!3m5!1s0x94ce8ab8805a7507:0x1d815504b055e30a!8m2!3d-23.446062!4d-46.5040827!16s%2Fg%2F11lcc377jh?entry=ttu"}
                        component="a"
                        variant="h5"
                        color="dark"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                       {end}
                      </MKTypography>
                    </MKBox>
                </MKTypography>
              ))}
            </MKBox>
          </Grid>
          {menus.map(({ name: title, items }) => (
            <Grid key={title} item xs={6} md={2} sx={{ mb: 3, mt:3.3  }}>
              <MKTypography
                display="block"
                variant="h5"
                fontWeight="bold"
                textTransform="capitalize"
                mb={1}
              >
                {title}
              </MKTypography>
              <MKBox component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
                {items.map(({ name, route, href }) => (
                  <MKBox key={name} component="li" p={0} m={0} lineHeight={1.25}>
                    {href ? (
                      <MKTypography
                        component="a"
                        href={href}
                        target="_self"
                        rel="noreferrer"
                        variant="h5"
                        fontWeight="regular"
                        textTransform="capitalize"
                      >
                        {name}
                      </MKTypography>
                    ) : (
                      <MKTypography
                        component={Link}
                        to={route}
                        variant="h5"
                        fontWeight="regular"
                        textTransform="capitalize"
                      >
                        {name}
                      </MKTypography>
                    )}
                  </MKBox>
                ))}
              </MKBox>
            </Grid>
          ))}
        </Grid>
          <Grid item xs={12} sx={{ textAlign: "center", my: 3 }}>
            {copyright}
          </Grid>
      </Container>
    </MKBox>
  );
}

// Typechecking props for the DefaultFooter
DefaultFooter.propTypes = {
  content: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array])).isRequired,
};

export default DefaultFooter;
